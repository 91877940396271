import React, { useContext } from 'react';
import { graphql } from 'gatsby';
import PortableText from 'react-portable-text';
import loadable from '@loadable/component';

import { 
  Container, 
  Typography,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
  withStyles,
  useMediaQuery,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Hero } from '../components/General/Hero';
import { WaveUpSVG } from '../components/WaveSVGs/WaveUpSVG';
import { SEO } from '../components/SEO';

import FormModalContext from '../context/FormModalContext';

const Form = loadable(() => import('../components/ContactForm/Form'));

const useStyles = makeStyles((theme) => ({
	text: {
		color: '#4B5B69',
		fontFamily: 'Roboto',
    fontSize: '1.125rem',
		lineHeight: 1.8,
		'& a': {
			color: theme.workwaveBlue,
		},
	},
  sectionHeader: {
    color: '#002D5C', 
    lineHeight: 1.8, 
    paddingTop: '2rem',
		[theme.breakpoints.down('md')]: {
			lineHeight: 1.2,
		},
  },
	headerCell: {
		background: '#002D5C',
		border: '2px solid #C3CAD9',
		// borderRadius: '20px 0px 0px 0px',
	},
	header: {
		fontWeight: 600,
		color: 'white',
	},
}));

const StyledCell = withStyles({
  root: {
    border: '2px solid #C3CAD9',
  },
})(TableCell);

const ACHPage = ({ data, location }) => {
	const ach = data.ach.edges[0].node;
	const classes = useStyles();

	const { formModalOpen } = useContext(FormModalContext);

	const { hero, _rawUpperPageContent, _rawLowerPageContent, noindex, metaDescription, metaTitle } = ach;
  const sm = useMediaQuery('(max-width: 600px)');
	return (
		<>
			<SEO title={metaTitle} description={metaDescription} noindex={noindex} />

			<Hero hero={hero} />
			<Container style={{ marginBottom: sm ? '8rem' : '15rem' }}>
				<Typography className={classes.text}>
					<PortableText
						content={_rawUpperPageContent}
						serializers={{
							h2: ({ children }) => (
								<Typography
									variant='h2'
									className={classes.sectionHeader}>
									{children}
								</Typography>
							),
							h4: ({ children }) => (
								<Typography
									variant='h5'
									style={{ color: '#002D5C', fontWeight: 700 }}>
									{children}
								</Typography>
							),
							li: ({ children }) => (
                <li className={classes.text}>{children}</li>
							),
							normal: ({ children }) => (
								<Typography
									variant='body1'
									style={{
										fontWeight: 400,
										lineHeight: 1.8,
										marginTop: '18px',
										marginBottom: '18px',
                    fontFamily: 'Roboto',
									}}>
									{children}
								</Typography>
							),
              em: ({ children }) => (
                <span style={{ fontStyle: 'italic' }}>{children}</span>
              ),
							emailPhoneLink: (props) => {
								return <a href={props.emailPhoneHref}>{props.children[0]}</a>;
							},
						}}
					/>
				</Typography>

				<TableContainer style={{ padding: '2rem 0' }}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell align='left' className={classes.headerCell}>
									<Typography variant='h5' className={classes.header}>
										Code
									</Typography>
								</TableCell>
								<TableCell align='left' className={classes.headerCell}>
									<Typography variant='h5' className={classes.header}>
										Description
									</Typography>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
              <TableRow>
                <StyledCell align='left'>
                  <Typography variant='body1' className={classes.text}>R01</Typography>
                </StyledCell>
                <StyledCell align='left'>
                  <Typography variant='body1' className={classes.text}>Insufficient Funds</Typography>
                </StyledCell>
              </TableRow>
              <TableRow>
                <StyledCell align='left'>
                  <Typography variant='body1' className={classes.text}>R02</Typography>
                </StyledCell>
                <StyledCell align='left'>
                  <Typography variant='body1' className={classes.text}>Account Closed</Typography>
                </StyledCell>
              </TableRow>
              <TableRow>
                <StyledCell align='left'>
                  <Typography variant='body1' className={classes.text}>R03</Typography>
                </StyledCell>
                <StyledCell align='left'>
                  <Typography variant='body1' className={classes.text}>No Account / Unable to Locate Account</Typography>
                </StyledCell>
              </TableRow>
              <TableRow>
                <StyledCell align='left'>
                  <Typography variant='body1' className={classes.text}>R04</Typography>
                </StyledCell>
                <StyledCell align='left'>
                  <Typography variant='body1' className={classes.text}>Invalid Account Number</Typography>
                </StyledCell>
              </TableRow>
              <TableRow>
                <StyledCell align='left'>
                  <Typography variant='body1' className={classes.text}>R05</Typography>
                </StyledCell>
                <StyledCell align='left'>
                  <Typography variant='body1' className={classes.text}>Unauthorized Debit to Consumer Account Using Corporate SEC Code</Typography>
                </StyledCell>
              </TableRow>
              <TableRow>
                <StyledCell align='left'>
                  <Typography variant='body1' className={classes.text}>R06</Typography>
                </StyledCell>
                <StyledCell align='left'>
                  <Typography variant='body1' className={classes.text}>Returned Per ODFI Request</Typography>
                </StyledCell>
              </TableRow>
              <TableRow>
                <StyledCell align='left'>
                  <Typography variant='body1' className={classes.text}>R07</Typography>
                </StyledCell>
                <StyledCell align='left'>
                  <Typography variant='body1' className={classes.text}>Authorization Revoked by Customer</Typography>
                </StyledCell>
              </TableRow>
              <TableRow>
                <StyledCell align='left'>
                  <Typography variant='body1' className={classes.text}>R08</Typography>
                </StyledCell>
                <StyledCell align='left'>
                  <Typography variant='body1' className={classes.text}>Payment Stopped</Typography>
                </StyledCell>
              </TableRow>
              <TableRow>
                <StyledCell align='left'>
                  <Typography variant='body1' className={classes.text}>R09</Typography>
                </StyledCell>
                <StyledCell align='left'>
                  <Typography variant='body1' className={classes.text}>Uncollected Funds</Typography>
                </StyledCell>
              </TableRow>
              <TableRow>
                <StyledCell align='left'>
                  <Typography variant='body1' className={classes.text}>R10</Typography>
                </StyledCell>
                <StyledCell align='left'>
                  <Typography variant='body1' className={classes.text}>Customer Advises Originator is Not Known to Receiver and/or Originator is Not Authorized by Receiver to Debit Receiver's Account</Typography>
                </StyledCell>
              </TableRow>
              <TableRow>
                <StyledCell align='left'>
                  <Typography variant='body1' className={classes.text}>R11</Typography>
                </StyledCell>
                <StyledCell align='left'>
                  <Typography variant='body1' className={classes.text}>Customer Advises Entry Not in Accordance with the Terms of the Authorization</Typography>
                </StyledCell>
              </TableRow>
              <TableRow>
                <StyledCell align='left'>
                  <Typography variant='body1' className={classes.text}>R29</Typography>
                </StyledCell>
                <StyledCell align='left'>
                  <Typography variant='body1' className={classes.text}>Corporate Customer Advises Not Authorized</Typography>
                </StyledCell>
              </TableRow>
						</TableBody>
					</Table>
				</TableContainer>

				<Typography className={classes.text}>
					<PortableText
						content={_rawLowerPageContent}
						serializers={{
							h2: ({ children }) => (
								<Typography
									variant='h2'
									className={classes.sectionHeader}>
									{children}
								</Typography>
							),
							h4: ({ children }) => (
								<Typography
									variant='h5'
									style={{ color: '#002D5C', fontWeight: 700 }}>
									{children}
								</Typography>
							),
							li: ({ children }) => (
                <li className={classes.text}>{children}</li>
							),
							normal: ({ children }) => (
								<Typography
									variant='body1'
									style={{
										fontWeight: 400,
										lineHeight: 1.8,
										marginTop: '18px',
										marginBottom: '18px',
                    fontFamily: 'Roboto',
									}}>
									{children}
								</Typography>
							),
              em: ({ children }) => (
                <span style={{ fontStyle: 'italic' }}>{children}</span>
              ),
							emailPhoneLink: (props) => {
								return <a href={props.emailPhoneHref}>{props.children[0]}</a>;
							},
						}}
					/>
				</Typography>


				{formModalOpen ? (
					<Form
						privacy
						noForm
						location={location}
						pardotUrl='https://go.workwave.com/l/1042281/2023-11-16/65swnn'
					/>
				) : null}
			</Container>
			<div>
				<WaveUpSVG height='213' width='100%' fill='#f4f8ff' />
			</div>
		</>
	);
};

export const query = graphql`
	query ACHPageQuery {
		ach: allSanityAchTransactionGuidelines {
			edges {
				node {
					metaTitle
					metaDescription
          noindex
					hero {
						backgroundImage {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						_rawContent
					}
          _rawUpperPageContent
          _rawLowerPageContent
				}
			}
		}
	}
`;

export default ACHPage;
